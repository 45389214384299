// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-organ-tsx": () => import("./../../../src/pages/organ.tsx" /* webpackChunkName: "component---src-pages-organ-tsx" */),
  "component---src-pages-profil-verejneho-obstaravatela-tsx": () => import("./../../../src/pages/profil-verejneho-obstaravatela.tsx" /* webpackChunkName: "component---src-pages-profil-verejneho-obstaravatela-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/IndexPage.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-markdown-post-template-tsx": () => import("./../../../src/templates/MarkdownPostTemplate.tsx" /* webpackChunkName: "component---src-templates-markdown-post-template-tsx" */),
  "component---src-templates-projekty-page-tsx": () => import("./../../../src/templates/ProjektyPage.tsx" /* webpackChunkName: "component---src-templates-projekty-page-tsx" */),
  "component---src-templates-verejne-obstaravanie-page-tsx": () => import("./../../../src/templates/VerejneObstaravaniePage.tsx" /* webpackChunkName: "component---src-templates-verejne-obstaravanie-page-tsx" */),
  "component---src-templates-wordpress-page-template-tsx": () => import("./../../../src/templates/WordpressPageTemplate.tsx" /* webpackChunkName: "component---src-templates-wordpress-page-template-tsx" */),
  "component---src-templates-wordpress-post-template-tsx": () => import("./../../../src/templates/WordpressPostTemplate.tsx" /* webpackChunkName: "component---src-templates-wordpress-post-template-tsx" */)
}

